<template>
  <loader v-if="loadingState.judgeTable" />
  <div v-else>
    <judge-screenshot-uploader
      v-if="!loadingState.judgeTableSave"
      v-model="files"
      :game-id="gameInfo.id"
      :match-id="match.id"
      @showImage="loadImage"
    />
    <span v-if="title" class="section-title">{{ title }}</span>

    <div
      class="pvp-table stripped adaptive"
      :class="{ 'no-kills': match.disableMatchKills }"
    >
      <div class="table-header">
        <div class="table-row">
          <cell-header t="teams.single" />
          <cell-header t="players.few" />
          <cell-header t="screenshots.few" />
          <cell-header t="places.single" />
          <cell-header
            v-if="!match.disableMatchKills"
            t="global.killsCount"
          />
          <cell-header t="scores.for_win" />
          <cell-header t="scores.single" />
          <cell-header t="matches.tech_shortDefeat" />
        </div>
      </div>
      <div class="table-body">
        <div
          v-for="(team, key) in teams"
          :key="key"
          class="table-row"
        >
          <cell-team :hash="team.hash" />
          <cell-simple t="players.few">
            <div class="users">
              <BaseUserLink
                v-for="user in team.players"
                :key="user.hash"
                tabindex="-1"
                image-size="32"
                :hash="user.hash"
              >
                <template slot="username">{{
                  user.gameNick
                }}</template>
              </BaseUserLink>
            </div>
          </cell-simple>
          <cell-simple t="screenshots.few">
            <div class="screenshot">
              <pvp-btn
                v-for="(image, index) in getTeamImgScreens(team.hash)"
                :key="image.id"
                variant="link"
                class="screenshot-link"
                @click="loadImage(image.id)"
              >
                {{ $t('screenshots.open', { n: `#${index + 1}` }) }}
              </pvp-btn>
            </div>
          </cell-simple>
          <cell-simple t="places.single">
            <BaseInput
              v-model="teamPlaces[team.hash]"
              class="column-content"
              :disabled="teamExcluded[team.hash]"
              type="number"
            />
          </cell-simple>
          <cell-simple
            v-if="!match.disableMatchKills"
            t="global.killsCount"
          >
            <BaseInput
              v-model="teamKills[team.hash]"
              class="column-content"
              :disabled="teamExcluded[team.hash]"
              type="number"
            />
          </cell-simple>
          <cell-simple t="scores.for_win" :value="team.win_scores" />
          <cell-simple t="scores.single">{{
            team.scores || '&mdash;'
          }}</cell-simple>
          <cell-simple t="matches.tech_shortDefeat">
            <BaseCheckbox
              v-model="teamExcluded[team.hash]"
              @input="dropKillsAndPlace(team.hash)"
            />
          </cell-simple>
        </div>
      </div>
    </div>
    <InfoBox
      v-for="(item, key) in infoList"
      :key="key"
      :variant="item.variant"
      >{{ item.text }}
    </InfoBox>
    <div class="confirm-box">
      <pvp-btn
        v-if="gameInfo.status === 5"
        variant="secondary"
        :is-loading="loadingState.judgeTableSave"
        @click="unPublish"
      >
        {{ $t('matches.edit_unpublish') }}
      </pvp-btn>
      <pvp-btn
        :is-loading="loadingState.judgeTableSave"
        @click="saveChanges"
        >{{ $t('save.single') }}
      </pvp-btn>
      <pvp-btn
        :is-loading="loadingState.judgeTableSave"
        @click="saveAndPublish"
      >
        {{ $t('matches.edit_publish') }}
      </pvp-btn>
    </div>
    <pvp-modal
      v-if="loadedImage"
      :value="true"
      class="full-width"
      @input="loadedImage = ''"
    >
      <img
        class="img"
        :class="{ full: isFullImage }"
        :src="loadedImage"
      />
      <pvp-btn
        variant="clear"
        :icon-left="isFullImage ? 'sizeNormal' : 'sizeFull'"
        @click="isFullImage = !isFullImage"
      >
      </pvp-btn>
    </pvp-modal>
  </div>
</template>

<script>
import InfoBox from '@components/BaseComponents/InfoBox.vue';
import JudgeScreenshotUploader from '@components/Match/TeamFFA/Judge/JudeScreenshotUploder.vue';
import CellHeader from '@components/TableCells/CellHeader.vue';
import CellTeam from '@components/TableCells/CellTeam.vue';
import CellSimple from '@components/TableCells/CellSimple.vue';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import BaseCheckbox from '@components/BaseComponents/Form/BaseCheckbox.vue';
import BaseUserLink from '@components/BaseComponents/BaseUserLink.vue';

export default {
  name: 'JudgeTable',
  components: {
    CellSimple,
    CellTeam,
    CellHeader,
    JudgeScreenshotUploader,
    InfoBox,
    BaseInput,
    BaseCheckbox,
    BaseUserLink,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    game: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    loadingState: {
      judgeTable: true,
      judgeTableSave: false,
    },

    files: [],
    infoList: [],
    timeout: null,

    teamPlaces: {},
    teamKills: {},
    teamExcluded: {},
    loadedImage: '',
    isFullImage: false,
  }),
  computed: {
    ...mapGetters('matches', ['getMatchGame', 'getMatch']),
    ...mapGetters('teams', ['getTeam']),
    match() {
      return this.getMatch(this.id);
    },
    gameInfo() {
      const game = this.getMatchGame({
        matchId: this.id,
        gameIndex: this.game,
      });
      game.status =
        this.match?.games?.[this.game]?.status || game.status;
      return game;
    },
    teams() {
      return this.gameInfo.slots.map((game) => {
        const { players } = this.getTeam(game.hash);

        return {
          ...game,
          players,
        };
      });
    },
  },
  watch: {
    game: 'parseResults',
  },
  created() {
    this.loadingState.judgeTable = true;
    this.fetchTeamFFAJudgeResult(this.id).finally(() => {
      this.parseResults();
      this.loadingState.judgeTable = false;
    });
  },
  methods: {
    ...mapActions('matches', [
      'fetchMatch',
      'fetchTeamFFAJudgeResult',
      'fetchTeamFFAJudgeSaveResult',
      'fetchTeamFFAJudgePublish',
      'fetchMatchUserAbilities',
    ]),

    getTeamImgScreens(hash) {
      return this.gameInfo?.teamImgScreens?.filter(
        (screen) => screen.team === hash,
      );
    },

    updateMatchInfo() {
      this.fetchMatch(this.id);
      this.fetchMatchUserAbilities(this.id);
    },

    parseResults() {
      this.files = [];
      this.infoList = [];
      const { places, kills, excluded } = this.teams.reduce(
        (result, team) => {
          result.places[team.hash] = team.place;
          result.kills[team.hash] = team.kills;
          result.excluded[team.hash] = team.excluded === 'Y';
          return result;
        },
        { places: {}, kills: {}, excluded: {} },
      );
      this.teamPlaces = places;
      this.teamKills = kills;
      this.teamExcluded = excluded;
    },

    dropKillsAndPlace(hash) {
      this.teamKills[hash] = 0;
      this.teamPlaces[hash] = null;
    },

    unPublish() {
      this.infoList = [];
      this.loadingState.judgeTableSave = true;
      api
        .post(
          `/match/${this.id}/judge/result/${this.gameInfo.id}/unpublish`,
        )
        .then((data) => {
          if (data.success) {
            this.infoList.push({
              variant: 'success',
              text: this.$t('matches.edit_unpublished'),
            });
            this.updateMatchInfo();
          }
        })
        .finally(() => {
          this.loadingState.judgeTableSave = false;
        });
    },

    uploadFiles(file) {
      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('type', 'matchGameResult');
      formData.append('matchId', this.match.id);

      file.loading = true;
      return api
        .post('/file/uploadandresize', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress(progressEvent) {
            const percent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            file.loadedPercent = percent;
          },
        })
        .then((data) => {
          file.success = true;
          return data;
        })
        .catch(() => {
          file.error = this.$t('save.error');
          return false;
        })
        .finally(() => {
          file.loading = false;
        });
    },

    saveChanges() {
      this.infoList = [];
      this.loadingState.judgeTableSave = true;

      const saveChanges = (addedFiles = []) => {
        const imgScreens = {};
        if (addedFiles.length) {
          imgScreens.add = addedFiles;
        }
        return this.fetchTeamFFAJudgeSaveResult({
          id: {
            match: this.id,
            game: this.gameInfo.id,
          },
          results: this.teams.map(({ hash }) => ({
            hash,
            place: this.teamPlaces[hash],
            kills: this.teamKills[hash],
            excluded: this.teamExcluded[hash] ? 'Y' : 'N',
          })),
          imgScreens,
        })
          .then((res) => {
            this.infoList.push({
              variant: 'success',
              text: this.$t('matches.edit_saved'),
            });
            this.results = {};
            return res;
          })
          .catch(({ error = '' }) => {
            if (_.isArray(error)) {
              error.forEach((err) => {
                this.infoList.push({
                  variant: 'error',
                  text: `${err.error} ${this.getTeam(err.hash).name}`,
                });
              });
            }
            if (error.imgScreens) {
              this.infoList.push({
                variant: 'error',
                text: error.imgScreens,
              });
            }
            this.infoList.push({
              variant: 'error',
              text: this.$t('matches.edit_saveError'),
            });
          })
          .finally(() => {
            this.files = [];
            this.loadingState.judgeTableSave = false;
          });
      };

      if (this.files.length) {
        return Promise.all(this.files?.map(this.uploadFiles) || [])
          .then((data) => {
            this.files = [];
            saveChanges(data.map(({ id }) => id));
          })
          .catch(() => {
            this.loadingState.judgeTableSave = false;
            this.infoList.push({
              variant: 'error',
              text: this.$t('matches.edit_saveError'),
            });
          });
      }

      return saveChanges();
    },

    async saveAndPublish() {
      const { success } = await this.saveChanges();
      if (success) {
        api
          .post(
            `/match/${this.id}/judge/result/${this.gameInfo.id}/publish`,
          )
          .then(() => {
            this.infoList.push({
              variant: 'success',
              text: this.$t('matches.edit_published'),
            });
            this.updateMatchInfo();
          })
          .catch(({ error = [] }) => {
            error.forEach((text) => {
              this.infoList.push({
                variant: 'error',
                text,
              });
            });
          });
      }
    },

    loadImage(screenId) {
      this.isFullImage = false;
      api
        .get(
          `/match/${this.id}/player/result/${this.gameInfo.id}/screen/${screenId}`,
        )
        .then((data) => {
          this.loadedImage = data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.section-title {
  margin-top: 20px;
}

.pvp-table {
  position: relative;
  @include max-tablet() {
    margin-left: -12px;
    margin-right: -12px;
  }
}

.no-kills .table {
  &-row {
    @include min-laptop() {
      display: grid;
      grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr 0.5fr;
    }
  }
}

.table {
  &-header {
    @include max-laptop() {
      display: none;
    }
  }

  &-row {
    @include min-laptop() {
      display: grid;
      grid-template-columns: 2fr 2fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
    }
  }

  &-column {
    overflow: hidden;

    & + & {
      padding-left: 10px;
    }

    @include max-laptop() {
      ::v-deep .column-title {
        flex: 0 0 120px;
      }
    }
  }
}

.user-link {
  display: flex;

  & + & {
    margin-top: 8px;
  }
}

.screenshot {
  display: block;

  &-link {
    display: block;
    font-size: 12px;
    line-height: 1.2;
    white-space: normal;
    text-align: left;
    width: 100%;

    & + & {
      margin-top: 4px;
    }
  }
}

.confirm-box {
  text-align: center;
  margin-top: 20px;

  .button {
    width: 230px;
    margin: 10px 8px 0;
  }
}

.loader {
  margin: 30px auto;
}

.pvp-info-box {
  margin-top: 8px;
}

.pvp-modal {
  img {
    margin-top: 20px;

    &:not(.full) {
      max-width: 100%;
    }
  }

  .button {
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 45px;
    height: 45px;
    color: rgba(white, 0.4);
  }
}
</style>
