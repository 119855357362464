<template>
  <div class="judge">
    <div v-if="!isEditMode" class="manual">
      <manual-create-server
        v-if="userStatus.abilities.canJudgeCreateServer"
        :id="id"
        class="manual-item"
        @success="updateMatchInfo"
      />

      <template v-if="currentGame && currentGame.status === 3">
        <manual-cancel-server
          v-if="userStatus.abilities.canJudgeCancelServer"
          :id="id"
          class="manual-item"
          @success="updateMatchInfo"
        />

        <manual-finish-game
          v-if="userStatus.abilities.canJudgeCompleteGame"
          :id="id"
          class="manual-item"
          @success="updateMatchInfo"
        />
      </template>
    </div>

    <div
      v-if="userStatus.abilities.canJudgeEditResults"
      class="judge-buttons"
    >
      <pvp-btn v-if="isEditMode" @click="toggleMode">
        {{ $t('matches.edit_end') }}
      </pvp-btn>
      <pvp-btn v-else icon-left="pencil" @click="toggleMode">
        {{ $t('matches.edit_start') }}
      </pvp-btn>
    </div>

    <div class="judge-buttons">
      <pvp-btn
        v-if="userStatus.abilities.canJudgeStartRent && !isEditMode"
        @click="startRentGame"
      >
        {{ $t('matches.server_start_button_match') }}
      </pvp-btn>

      <pvp-btn
        v-else-if="userStatus.abilities.canJudgeStart && !isEditMode"
        @click="startGame"
      >
        {{ $t('matches.server_start_button_default') }}
      </pvp-btn>

      <pvp-btn
        v-if="userStatus.abilities.canJudgeCancelResults"
        :is-loading="loadingState.cancelResults"
        variant="secondary"
        @click="cancelMatchResults"
      >
        {{ $t('matches.results_cancel') }}
      </pvp-btn>
      <pvp-btn
        v-if="userStatus.abilities.canJudgeConfirmResults"
        :is-loading="loadingState.confirmResults"
        @click="confirmMatchResults"
      >
        {{ $t('matches.results_confirm') }}
      </pvp-btn>
    </div>

    <div class="info">
      <InfoBox
        v-for="(item, key) in infoList"
        :key="key"
        :variant="item.variant"
      >
        {{ item.text }}
      </InfoBox>
    </div>
  </div>
</template>

<script>
import InfoBox from '@components/BaseComponents/InfoBox.vue';
import ManualCreateServer from '@components/Match/TeamFFA/Judge/ManualCreateServer.vue';
import ManualCancelServer from '@components/Match/TeamFFA/Judge/ManualCancelServer.vue';
import ManualFinishGame from '@components/Match/TeamFFA/Judge/ManualFinishGame.vue';

export default {
  name: 'JudgeInfo',
  components: {
    ManualFinishGame,
    ManualCancelServer,
    ManualCreateServer,
    InfoBox,
  },
  model: {
    prop: 'isEditMode',
    event: 'input',
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    game: {
      type: Number,
      required: true,
    },
    matchStatus: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loadingState: {
      cancelResults: false,
      confirmResults: false,
    },
    infoList: [],
  }),
  computed: {
    ...mapGetters('matches', ['getMatch']),
    ...mapGetters('users', ['getUserMatchStatus']),
    ...mapGetters('tournaments', ['getTournament']),
    match() {
      return this.getMatch(this.id);
    },

    currentGame() {
      return this.match.games[this.game - 1];
    },

    userStatus() {
      return this.getUserMatchStatus(this.id);
    },

    tournament() {
      return this.getTournament(this.match.idTournament);
    },
  },
  methods: {
    ...mapActions('matches', [
      'fetchMatch',
      'fetchMatchUserAbilities',
    ]),
    toggleMode() {
      if (this.isEditMode) {
        this.fetchMatch(this.id);
      }
      this.$emit('input', !this.isEditMode);
    },

    startRentGame() {
      api
        .get(`/match/${this.id}/judge/servers/rentstart`)
        .then((data) => {
          if (data.success) {
            this.infoList.push({
              variant: 'success',
              text: this.$t('matches.server_status_started'),
            });
          } else {
            this.infoList.push({
              variant: 'error',
              text: this.$t('matches.server_status_error'),
            });
          }
        })
        .catch(() => {
          this.infoList.push({
            variant: 'error',
            text: this.$t('matches.server_status_error'),
          });
        });
    },

    startGame() {
      this.infoList = [];
      api
        .get(`/match/${this.id}/judge/servers/start`)
        .then((data) => {
          if (data.success) {
            this.infoList.push({
              variant: 'success',
              text: this.$t('matches.server_status_started'),
            });
            this.updateMatchInfo(this.id);
          } else {
            this.infoList.push({
              variant: 'error',
              text: this.$t('matches.server_status_error'),
            });
          }
        })
        .catch(() => {
          this.infoList.push({
            variant: 'error',
            text: this.$t('matches.server_status_error'),
          });
        });
    },

    updateMatchInfo() {
      this.fetchMatch(this.id);
      this.fetchMatchUserAbilities(this.id);
    },

    confirmMatchResults() {
      this.infoList = [];
      this.loadingState.confirmResults = true;
      api
        .get(`/match/${this.id}/judge/result/confirm`)
        .then((data) => {
          if (data.success) {
            this.updateMatchInfo();
            this.infoList.push({
              variant: 'success',
              text: this.$t('matches.results_confirmed'),
            });
          } else {
            this.infoList.push({
              variant: 'error',
              text: this.$t('matches.results_errors_confirm'),
            });
          }
        })
        .catch(() => {
          this.infoList.push({
            variant: 'error',
            text: this.$t('matches.results_errors_confirm'),
          });
        })
        .finally(() => {
          this.loadingState.confirmResults = false;
          this.$emit('input', false);
        });
    },

    cancelMatchResults() {
      this.infoList = [];
      this.loadingState.cancelResults = true;
      api
        .get(`/match/${this.id}/judge/result/cancel`)
        .then((data) => {
          if (data.success) {
            this.updateMatchInfo();
            this.infoList.push({
              variant: 'success',
              text: this.$t('matches.results_canceled'),
            });
          } else {
            this.infoList.push({
              variant: 'error',
              text: this.$t('matches.results_errors_cancel'),
            });
          }
        })
        .catch(() => {
          this.infoList.push({
            variant: 'error',
            text: this.$t('matches.results_errors_cancel'),
          });
        })
        .finally(() => {
          this.loadingState.cancelResults = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.judge {
  &-start {
    margin: 20px 0;

    .button {
      margin: 8px 0;
    }
  }

  &-buttons {
    margin: 12px 0 20px;

    .button {
      margin-top: 8px;

      .button + .button {
        margin-left: 1em;
      }
    }
  }
}

.info {
  margin: 10px 0;
}

.manual {
  margin-top: 2px;
  @include min-tablet() {
    display: flex;
  }

  &-item {
    padding: 30px 15px;
    background-color: rgba(black, 0.21);
    @include min-tablet() {
      flex: 1 1 0;
      & + & {
        margin-left: 2px;
      }
    }
    @include max-tablet() {
      margin-top: 2px;
    }
  }
}
</style>
