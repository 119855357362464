<template>
  <div class="server">
    <h4>{{ $t('matches.server_creation_name') }}</h4>
    <i18n
      path="matches.server_creation_description"
      tag="p"
      class="server-description"
    >
      <b slot="gameNum"
        >{{ $t('matches.server_creation_game') }} {{ gameNumber }}</b
      >
    </i18n>
    <pvp-btn icon-left="plus" @click="toggleStartMatchModal(true)">
      {{ $t('matches.server_creation_create') }}
    </pvp-btn>
    <pvp-modal v-model="isStartMatchModal" width="580">
      <h4>{{ $t('matches.server_creation_name') }}</h4>
      <BaseForm class="server-creation-box" @submit="updateServer">
        <BaseSelect
          v-if="regionData.length"
          v-model="formData.serverRegion"
          value-key="code"
          label-key="label"
          :label="$t('players.region_label')"
          :readonly="true"
          :clearable="false"
          :options="regionData"
          :status="errors.serverRegion ? 'error' : ''"
          :message="errors.serverRegion"
          @input="errors.serverRegion = ''"
        />

        <BaseInput
          v-model="formData.serverName"
          :label="$t('matches.server_info_name')"
          :error-message.sync="errors.serverName"
        />
        <BaseInput
          v-model="formData.serverPassword"
          :label="$t('global.password')"
          :error-message.sync="errors.serverPassword"
        />
        <pvp-btn
          type="submit"
          :is-loading="loadingState.create"
          icon-left="plus"
        >
          {{ $t('matches.server_creation_create') }}
        </pvp-btn>
      </BaseForm>
    </pvp-modal>
  </div>
</template>

<script>
import { generatePassword } from '@utils/generatePassword';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';
import BaseSelect from '@components/BaseComponents/Form/Select.vue';

export default {
  name: 'ManualCreateServer',
  components: {
    BaseInput,
    BaseForm,
    BaseSelect,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    loadingState: {
      create: false,
    },
    isStartMatchModal: false,
    formData: {
      serverName: '',
      serverPassword: '',
      serverRegion: '',
    },
    errors: {
      serverName: '',
      serverPassword: '',
      serverRegion: '',
      common: '',
    },
  }),
  computed: {
    ...mapGetters('matches', ['getMatch']),
    ...mapGetters('tournaments', ['getTournament']),
    ...mapGetters('application', [
      'getWfShards',
      'getPubgShards',
      'getGame',
    ]),
    match() {
      return this.getMatch(this.id);
    },
    gameNumber() {
      const gameIndex = +this.match?.games?.findIndex(
        (game) => game.status === 1,
      );
      return gameIndex + 1;
    },
    tournament() {
      return this.getTournament(this.match?.idTournament);
    },
    gameCode() {
      return this.getGame(this.tournament?.idGame)?.code;
    },

    regionData() {
      if (this.gameCode === 'wf') {
        return this.getWfShards;
      }
      if (this.gameCode === 'pub') {
        return this.getPubgShards;
      }
      return [];
    },
  },
  created() {
    this.formData.serverRegion =
      this.gameCode === 'pub' ? 'pc-ru' : '';
    this.formData.serverName = `${this.tournament.name.replace(
      /\W+/gi,
      '_',
    )}_G${this.gameNumber}`;
    this.formData.serverPassword = generatePassword();
  },
  methods: {
    toggleStartMatchModal(isOpen) {
      this.isStartMatchModal = isOpen;
    },
    updateServer() {
      this.loadingState.create = true;
      api
        .post(`/match/${this.id}/judge/servers/update`, this.formData)
        .then(() => {
          this.toggleStartMatchModal(false);
          this.$emit('success');
        })
        .catch(({ error = '' }) => {
          if (!_.isArray(error)) {
            this.errors.common = 'test';
            return;
          }

          const errors = error.reduce((obj, err) => {
            let key = '';
            const message = err.message.replace(
              /`(.*?)`:/gi,
              (a, b) => {
                key = b;
                return '';
              },
            );

            obj[key] = obj[key] ? `${obj[key]}, ${message}` : message;
            return obj;
          }, {});

          this.errors = _.merge({}, this.errors, errors);
        })
        .finally(() => {
          this.loadingState.create = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.server {
  &-description {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  &-creation-box {
    margin: 30px auto 0;
    max-width: 388px;

    .select {
      text-align: left;
    }

    .button {
      margin-top: 30px;
    }
  }
}
</style>
