<template>
  <div class="finish">
    <div class="finish-text">{{ description }}</div>
    <pvp-btn :is-loading="loadingState.finish" @click="finishGame">
      {{ $t('matches.server_finish_button') }}
    </pvp-btn>
  </div>
</template>

<script>
export default {
  name: 'ManualFinishGame',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    loadingState: {
      finish: false,
    },
  }),
  computed: {
    ...mapGetters('matches', ['getMatch']),
    isLastGame() {
      const { games } = this.getMatch(this.id) || {};
      if (!games) {
        return false;
      }
      const gamesCount = games.length - 1;
      const currentGameIndex = games.findIndex(
        (game) => game?.status === 3,
      );
      return gamesCount === currentGameIndex;
    },
    description() {
      return this.$t(
        `matches.server_finish_${
          this.isLastGame ? 'lastGameDescription' : 'description'
        }`,
      );
    },
  },
  methods: {
    finishGame() {
      this.loadingState.finish = true;
      api
        .get(`/match/${this.id}/judge/servers/finish`)
        .then(() => {
          this.$emit('success');
        })
        .catch(() => {})
        .finally(() => {
          this.loadingState.finish = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.finish {
  &-text {
    font-size: 14px;
    line-height: 1.5;
    color: rgba(white, 0.43);
  }
}

.button {
  margin-top: 18px;
}
</style>
