<template>
  <Match
    v-if="!isLoading"
    :match-id="matchId"
    :round="matchRound"
    :match-data="matchData"
  />
</template>

<script>
import Match from '@views/Match.vue';

export default {
  name: 'MatchPage',
  components: {
    Match,
  },
  data() {
    return {
      tournament: null,
      isLoading: true,
      matchRound: {},
      matchData: null,
    };
  },
  computed: {
    matchId() {
      return Number(this.$route.params.id);
    },
    tournamentId() {
      return this.tournament.id;
    },
    // isHub() {
    //   return this.tournament.idSystem === 9;
    // },
  },
  // FIXIT: пока фриз по хабам коментим этот код. Тут лишний запрос на fetchMatch,
  // если возвращать хаба, надо удрать этот запрос в компоненте Match.vue
  async created() {
    this.matchData = await this.fetchMatch(this.matchId);
    this.matchRound = this.matchData.round;
    this.tournament = this.matchData.tournament;
    // if (this.isHub) {
    //   this.$router.push(
    //     `/hub/${this.tournamentId}/match/${this.matchId}`,
    //   );
    // }
    this.isLoading = false;
  },
  methods: {
    ...mapActions('matches', ['fetchMatch']),
  },
};
</script>
